import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
} from "../InfoSection/InfoElements";

import { TextWrapper } from "./LegalStuffElements";

function PrivacyPolicyGerman() {
  return (
    <InfoContainer className="info_container_class">
      <InfoWrapper className="info_wrapper_class">
        <InfoRow className="info_row_class">
          <Column1>
            <TextWrapper>
              <h1>Datenschutzerklärung</h1>
              <br />
              <h2>Datenschutz</h2>
              <br />
              <p>
                Wir haben diese Datenschutzerklärung (Fassung
                12.11.2020-311230666) verfasst, um Ihnen gemäß der Vorgaben der
                Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche
                Informationen wir sammeln, wie wir Daten verwenden und welche
                Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite
                haben. Leider liegt es in der Natur der Sache, dass diese
                Erklärungen sehr technisch klingen, wir haben uns bei der
                Erstellung jedoch bemüht die wichtigsten Dinge so einfach und
                klar wie möglich zu beschreiben.
              </p>
              <br />
              <h3>Automatische Datenspeicherung</h3>
              <br />
              <p>
                Wenn Sie heutzutage Webseiten besuchen, werden gewisse
                Informationen automatisch erstellt und gespeichert, so auch auf
                dieser Webseite. Wenn Sie unsere Webseite so wie jetzt gerade
                besuchen, speichert unser Webserver (Computer auf dem diese
                Webseite gespeichert ist) automatisch Daten wie • die Adresse
                (URL) der aufgerufenen Webseite • Browser und Browserversion •
                das verwendete Betriebssystem • die Adresse (URL) der zuvor
                besuchten Seite (Referrer URL) • den Hostname und die IP-Adresse
                des Geräts von welchem aus zugegriffen wird • Datum und Uhrzeit
                in Dateien (Webserver-Logfiles). In der Regel werden
                Webserver-Logfiles zwei Wochen gespeichert und danach
                automatisch gelöscht. Wir geben diese Daten nicht weiter, können
                jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
                rechtswidrigem Verhalten eingesehen werden.
              </p>
              <br />
              <h3>Cookies</h3>
              <br />
              <p>
                Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten
                zu speichern. Im Folgenden erklären wir, was Cookies sind und
                warum Sie genutzt werden, damit Sie die folgende
                Datenschutzerklärung besser verstehen.
              </p>
              <br />
              <h3> Was genau sind Cookies?</h3>
              <br />
              <p>
                Immer wenn Sie durch das Internet surfen, verwenden Sie einen
                Browser. Bekannte Browser sind beispielsweise Chrome, Safari,
                Firefox, Internet Explorer und Microsoft Edge. Die meisten
                Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese
                Dateien nennt man Cookies. Eines ist nicht von der Hand zu
                weisen: Cookies sind echt nützliche Helferlein. Fast alle
                Webseiten verwenden Cookies. Genauer gesprochen sind es
                HTTP-Cookies, da es auch noch andere Cookies für andere
                Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die
                von unserer Website auf Ihrem Computer gespeichert werden. Diese
                Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem
                “Hirn” Ihres Browsers, untergebracht. Ein Cookie besteht aus
                einem Namen und einem Wert. Bei der Definition eines Cookies
                müssen zusätzlich ein oder mehrere Attribute angegeben werden.
                Cookies speichern gewisse Nutzerdaten von Ihnen, wie
                beispielsweise Sprache oder persönliche Seiteneinstellungen.
                Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser
                die „userbezogenen“ Informationen an unsere Seite zurück. Dank
                der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen
                Ihre gewohnte Standardeinstellung. In einigen Browsern hat
                jedes Cookie eine eigene Datei, in anderen wie beispielsweise
                Firefox sind alle Cookies in einer einzigen Datei gespeichert.
                Es gibt sowohl Erstanbieter Cookies als auch
                Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
                unserer Seite erstellt, Drittanbieter-Cookies werden von
                Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie
                ist individuell zu bewerten, da jedes Cookie andere Daten
                speichert. Auch die Ablaufzeit eines Cookies variiert von ein
                paar Minuten bis hin zu ein paar Jahren. Cookies sind keine
                Software-Programme und enthalten keine Viren, Trojaner oder
                andere „Schädlinge“. Cookies können auch nicht auf Informationen
                Ihres PCs zugreifen. So können zum Beispiel Cookie-Daten
                aussehen:
              </p>
              <br />
              <p>• Name: _ga</p>
              <p>• Ablaufzeit: 2 Jahre</p>
              <p>• Verwendung: Unterscheidung der Webseitenbesucher</p>
              <p>• Beispielhafter Wert: GA1.2.1326744211.152311230666</p>
              <br />
              <p> Ein Browser sollte folgende Mindestgrößen unterstützen:</p>
              <p>• Ein Cookie soll mindestens 4096 Bytes enthalten können</p>
              <p>
                • Pro Domain sollen mindestens 50 Cookies gespeichert werden
                können
              </p>
              <p>
                • Insgesamt sollen mindestens 3000 Cookies gespeichert werden
                können
              </p>
              <br />
              <h3>Welche Arten von Cookies gibt es?</h3>
              <br />
              <p>
                Die Frage welche Cookies wir im Speziellen verwenden, hängt von
                den verwendeten Diensten ab und wird in der folgenden
                Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle
                möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies
                eingehen. Man kann 4 Arten von Cookies unterscheiden:
              </p>
              <br />
              <p>
                <b>Unbedingt notwendige Cookies</b>
              </p>
              <br />
              <p>
                Diese Cookies sind nötig, um grundlegende Funktionen der Website
                sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein
                User ein Produkt in den Warenkorb legt, dann auf anderen Seiten
                weitersurft und später erst zur Kasse geht. Durch diese Cookies
                wird der Warenkorb nicht gelöscht, selbst wenn der User sein
                Browserfenster schließt.
              </p>
              <br />
              <p>
                <b>Funktionelle Cookies</b>
              </p>
              <br />
              <p>
                Diese Cookies sammeln Infos über das Userverhalten und ob der
                User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe
                dieser Cookies auch die Ladezeit und das Verhalten der Website
                bei verschiedenen Browsern gemessen.
              </p>
              <br />
              <p>
                <b>Zielorientierte Cookies</b>
              </p>
              <br />
              <p>
                Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
                Beispielsweise werden eingegebene Standorte, Schriftgrößen oder
                Formulardaten gespeichert.
              </p>
              <br />
              <p>
                <b>Werbe-Cookies</b>
              </p>
              <br />
              <p>
                Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen
                dazu dem User individuell angepasste Werbung zu liefern. Das
                kann sehr praktisch, aber auch sehr nervig sein. Üblicherweise
                werden Sie beim erstmaligen Besuch einer Webseite gefragt,
                welche dieser Cookiearten Sie zulassen möchten. Und natürlich
                wird diese Entscheidung auch in einem Cookie gespeichert.
              </p>
              <br />
              <h3>Wie kann ich Cookies löschen?</h3>
              <br />
              <p>
                Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst.
                Unabhängig von welchem Service oder welcher Website die Cookies
                stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur
                teilweise zuzulassen oder zu deaktivieren. Zum Beispiel können
                Sie Cookies von Drittanbietern blockieren, aber alle anderen
                Cookies zulassen. Wenn Sie feststellen möchten, welche Cookies
                in Ihrem Browser gespeichert wurden, wenn Sie
                Cookie-Einstellungen ändern oder löschen wollen, können Sie dies
                in Ihren Browser-Einstellungen finden:
              </p>
              <br />
              <p>Chrome: Cookies in Chrome löschen, aktivieren und verwalten</p>
              <br />
              <p> Safari: Verwalten von Cookies und Websitedaten mit Safari</p>
              <br />
              <p>
                Firefox: Cookies löschen, um Daten zu entfernen, die Websites
                auf Ihrem Computer abgelegt haben
              </p>
              <br />
              <p>
                Internet Explorer: Löschen und Verwalten von Cookies Microsoft
              </p>
              <br />
              <p>Edge: Löschen und Verwalten von Cookies</p>
              <br />
              <p>
                Falls Sie grundsätzlich keine Cookies haben wollen, können Sie
                Ihren Browser so einrichten, dass er Sie immer informiert, wenn
                ein Cookie gesetzt werden soll. So können Sie bei jedem
                einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder
                nicht. Die Vorgangsweise ist je nach Browser verschieden. Am
                besten ist es Sie suchen die Anleitung in Google mit dem
                Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren
                Chrome” im Falle eines Chrome Browsers oder tauschen das Wort
                “Chrome” gegen den Namen Ihres Browsers, z.B. Edge, Firefox,
                Safari aus.
              </p>
              <br />
              <h3>Wie sieht es mit meinem Datenschutz aus?</h3>
              <br />
              <p>
                Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin
                ist festgehalten, dass das Speichern von Cookies eine
                Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt es
                allerdings noch sehr unterschiedliche Reaktionen auf diese
                Richtlinien. In Deutschland wurden die Cookie-Richtlinien nicht
                als nationales Recht umgesetzt. Stattdessen erfolgte die
                Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des
                Telemediengesetzes (TMG). Wenn Sie mehr über Cookies wissen
                möchten und technischen Dokumentationen nicht scheuen, empfehlen
                wir https://tools.ietf.org/html/rfc6265, dem Request for
                Comments der Internet Engineering Task Force (IETF) namens “HTTP
                State Management Mechanism”.
              </p>
              <br />
              <h3>Speicherung persönlicher Daten </h3>
              <br />
              <p>
                Persönliche Daten, die Sie uns auf dieser Website elektronisch
                übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder
                andere persönlichen Angaben im Rahmen der Übermittlung eines
                Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit
                dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen
                Zweck verwendet, sicher verwahrt und nicht an Dritte
                weitergegeben. Wir nutzen Ihre persönlichen Daten somit nur für
                die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich
                wünschen und für die Abwicklung der auf dieser Webseite
                angebotenen Dienstleistungen und Produkte. Wir geben Ihre
                persönlichen Daten ohne Zustimmung nicht weiter, können jedoch
                nicht ausschließen, dass diese Daten beim Vorliegen von
                rechtswidrigem Verhalten eingesehen werden. Wenn Sie uns
                persönliche Daten per E-Mail schicken – somit abseits dieser
                Webseite – können wir keine sichere Übertragung und den Schutz
                Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten
                niemals unverschlüsselt per E-Mail zu übermitteln. Die
                Rechtsgrundlage besteht nach Artikel 6  Absatz 1 a DSGVO
                (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die
                Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten
                geben. Sie können diesen Einwilligung jederzeit widerrufen –
                eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten
                im Impressum.
              </p>
              <br />
              <h3> Rechte laut Datenschutzgrundverordnung</h3>
              <br />
              <p>
                Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die
                folgende Rechte zu:
              </p>
              <br />
              <p>• Recht auf Berichtigung (Artikel 16 DSGVO)</p>
              <br />
              <p>
                • Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17
                DSGVO)
              </p>
              <br />
              <p>
                • Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
              </p>
              <br />
              <p>
                • Recht auf Benachrichtigung – Mitteilungspflicht im
                Zusammenhang mit der Berichtigung oder Löschung
                personenbezogener Daten oder der Einschränkung der Verarbeitung
                (Artikel 19 DSGVO)
              </p>
              <br />
              <p>• Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</p>
              <p>• Widerspruchsrecht (Artikel 21 DSGVO)</p>
              <br />
              <p>
                • Recht, nicht einer ausschließlich auf einer automatisierten
                Verarbeitung — einschließlich Profiling — beruhenden
                Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
              </p>
              <br />
              <p>
                Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
                Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen
                Ansprüche sonst in einer Weise verletzt worden sind, können Sie
                sich an die Bundesbeauftragte für den Datenschutz und die
                Informationsfreiheit (BfDI) wenden.
              </p>
              <br />
              <h3>TLS-Verschlüsselung mit https</h3>
              <br />
              <p>
                Wir verwenden https um Daten abhörsicher im Internet zu
                übertragen (Datenschutz durch Technikgestaltung Artikel 25
                Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer
                Security), einem Verschlüsselungsprotokoll zur sicheren
                Datenübertragung im Internet können wir den Schutz vertraulicher
                Daten sicherstellen. Sie erkennen die Benutzung dieser
                Absicherung der Datenübertragung am kleinen Schloßsymbol links
                oben im Browser und der Verwendung des Schemas https (anstatt
                http) als Teil unserer Internetadresse.
              </p>
              <br />
              <h3> Google Fonts Datenschutzerklärung</h3>
              <br />
              <p>
                Auf unserer Website verwenden wir Google Fonts. Das sind die
                “Google-Schriften” der Firma Google Inc. Für den europäischen
                Raum ist das Unternehmen Google Ireland Limited (Gordon House,
                Barrow Street Dublin 4, Irland) für alle Google-Dienste
                verantwortlich. Für die Verwendung von Google-Schriftarten
                müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen.
                Weiters werden auch keine Cookies in Ihrem Browser gespeichert.
                Die Dateien (CSS, Schriftarten/Fonts) werden über die
                Google-Domains fonts.googleapis.com und fonts.gstatic.com
                angefordert. Laut Google sind die Anfragen nach CSS und
                Schriften vollkommen getrennt von allen anderen Google-Diensten.
                Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben,
                dass Ihre Google-Kontodaten, während der Verwendung von Google
                Fonts, an Google übermittelt werden. Google erfasst die Nutzung
                von CSS (Cascading Style Sheets) und der verwendeten
                Schriftarten und speichert diese Daten sicher. Wie die
                Datenspeicherung genau aussieht, werden wir uns noch im Detail
                ansehen.
              </p>
              <br />
              <h3>Was sind Google Fonts? </h3>
              <br />
              <p>
                Früher nannte man Google Fonts auch Google Web Fonts. Dabei
                handelt es sich um ein interaktives Verzeichnis mit über 800
                Schriftarten, die Google kostenlos bereitstellt. Mit Google
                Fonts könnte man Schriften nutzen, ohne sie auf den eigenen
                Server hochzuladen. Doch um diesbezüglich jede
                Informationsübertragung zu Google-Servern zu unterbinden, haben
                wir die Schriftarten auf unseren Server heruntergeladen. Auf
                diese Weise handeln wir datenschutzkonform und senden keine
                Daten an Google Fonts weiter. Anders als andere Web-Schriften
                erlaubt uns Google uneingeschränkten Zugriff auf alle
                Schriftarten. Wir können also unlimitiert auf ein Meer an
                Schriftarten zugreifen und so das Optimum für unsere Webseite
                rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie
                auf https://developers.google.com/fonts/faq?tid=311230666.
              </p>
              <br />
              <h3>Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
              <br />
              <p>
                Mit Google Fonts können wir auf unserer eigenen Webseite
                Schriften nutzen, doch müssen sie nicht auf unseren eigenen
                Server hochladen. Google Fonts ist ein wichtiger Baustein, um
                die Qualität unserer Webseite hoch zu halten. Alle
                Google-Schriften sind automatisch für das Web optimiert und dies
                spart Datenvolumen und ist speziell für die Verwendung mit
                mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite
                besuchen, sorgt die niedrige Dateigröße für eine schnelle
                Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts.
                Unterschiedliche Bildsynthese-Systeme (Rendering) in
                verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten
                können zu Fehlern führen. Solche Fehler können teilweise Texte
                bzw. ganze Webseiten optisch verzerren. Dank des schnellen
                Content Delivery Networks (CDN) gibt es mit Google Fonts keine
                plattformübergreifenden Probleme. Google Fonts unterstützt alle
                gängigen Browser (Google Chrome, Mozilla Firefox, Apple
                Safari, Opera) und funktioniert zuverlässig auf den meisten
                modernen mobilen Betriebssystemen, einschließlich Android 2.2+
                und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google
                Fonts also, damit wir unser gesamtes Online-Service so schön und
                einheitlich wie möglich darstellen können.
              </p>
              <br />
              <h3> Welche Daten werden von Google gespeichert?</h3>
              <br />
              <p>
                Wenn Sie unsere Webseite besuchen, werden die Schriften über
                einen Google-Server nachgeladen. Durch diesen externen Aufruf
                werden Daten an die Google-Server übermittelt. So erkennt Google
                auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besuchen.
                Die Google Fonts API wurde entwickelt, um Verwendung,
                Speicherung und Erfassung von Endnutzerdaten auf das zu
                reduzieren, was für eine ordentliche Bereitstellung von
                Schriften nötig ist. API steht übrigens für „Application
                Programming Interface“ und dient unter anderem als
                Datenübermittler im Softwarebereich. Google Fonts speichert CSS-
                und Schrift-Anfragen sicher bei Google und ist somit
                geschützt. Durch die gesammelten Nutzungszahlen kann Google
                feststellen, wie gut die einzelnen Schriften ankommen. Die
                Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie
                beispielsweise Google Analytics. Zudem verwendet Google auch
                Daten des eigenen Web-Crawlers, um festzustellen, welche
                Webseiten Google-Schriften verwenden. Diese Daten werden in der
                BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer
                und Entwickler nützen das Google-Webservice BigQuery, um große
                Datenmengen untersuchen und bewegen zu können. Zu bedenken gilt
                allerdings noch, dass durch jede Google Font Anfrage auch
                Informationen wie Spracheinstellungen, IP-Adresse, Version des
                Browsers, Bildschirmauflösung des Browsers und Name des Browsers
                automatisch an die Google-Server übertragen werden. Ob diese
                Daten auch gespeichert werden, ist nicht klar feststellbar bzw.
                wird von Google nicht eindeutig kommuniziert.
              </p>
              <br />
              <h3> Wie lange und wo werden die Daten gespeichert?</h3>
              <br />
              <p>
                Anfragen für CSS-Assets speichert Google einen Tag lang auf
                seinen Servern, die hauptsächlich außerhalb der EU angesiedelt
                sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die
                Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage,
                über die man einfach und schnell z.B. das Design bzw. die
                Schriftart einer Webseite ändern kann. Die Font-Dateien werden
                bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel,
                die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn
                Millionen von Webseiten auf die gleichen Schriften verweisen,
                werden sie nach dem ersten Besuch zwischengespeichert und
                erscheinen sofort auf allen anderen später besuchten Webseiten
                wieder. Manchmal aktualisiert Google Schriftdateien, um die
                Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen
                und das Design zu verbessern.
              </p>
              <br />
              <h3>
                Wie kann ich meine Daten löschen bzw. die Datenspeicherung
                verhindern?
              </h3>
              <br />
              <p>
                Jene Daten, die Google für einen Tag bzw. ein Jahr speichert
                können nicht einfach gelöscht werden. Die Daten werden beim
                Seitenaufruf automatisch an Google übermittelt. Um diese Daten
                vorzeitig löschen zu können, müssen Sie den Google-Support auf
                https://support.google.com/?hl=de&tid=311230666 kontaktieren.
                Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie
                unsere Seite nicht besuchen. Anders als andere Web-Schriften
                erlaubt uns Google uneingeschränkten Zugriff auf alle
                Schriftarten. Wir können also unlimitiert auf ein Meer an
                Schriftarten zugreifen und so das Optimum für unsere Webseite
                rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie
                auf https://developers.google.com/fonts/faq?tid=311230666. Dort
                geht zwar Google auf datenschutzrelevante Angelegenheiten ein,
                doch wirklich detaillierte Informationen über Datenspeicherung
                sind nicht enthalten. Es ist relativ schwierig, von Google
                wirklich präzise Informationen über gespeicherten Daten zu
                bekommen. Welche Daten grundsätzlich von Google erfasst werden
                und wofür diese Daten verwendet werden, können Sie auch auf
                https://www.google.com/intl/de/policies/privacy/ nachlesen.
              </p>
              <br />
              <h3> Google Fonts Lokal Datenschutzerklärung</h3>
              <br />
              <p>
                Auf unserer Website nutzen wir Google Fonts der Firma Google
                Inc. Für den europäischen Raum ist das Unternehmen Google
                Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
                verantwortlich. Wir haben die Google-Schriftarten lokal, d.h.
                auf unserem Webserver – nicht auf den Servern von Google –
                eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern
                und somit auch keine Datenübertragung oder Speicherung.
              </p>
              <br />
            </TextWrapper>
          </Column1>

          <Column2>
            <TextWrapper>
              <h3>Eingebettete Social Media Elemente Datenschutzerklärung</h3>
              <br />
              <p>
                Wir binden auf unserer Webseite Elemente von Social Media
                Diensten ein um Bilder, Videos und Texte anzuzeigen. Durch den
                Besuch von Seiten die diese Elemente darstellen, werden Daten
                von Ihrem Browser zum jeweiligen Social Media Dienst übertragen
                und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.
                Die folgenden Links führen Sie zu den Seiten der jeweiligen
                Social Media Dienste wo erklärt wird, wie diese mit Ihren Daten
                umgehen:
              </p>
              <br />
              <p>
                • Instagram-Datenschutzrichtlinie:
                https://help.instagram.com/519522125107875
              </p>
              <br />
              <p>
                • Für YouTube gilt die Google Datenschutzerklärung:
                https://policies.google.com/privacy?hl=de
              </p>
              <br />
              <p>
                •
                Facebook-Datenrichtline: https://www.facebook.com/about/privacy
              </p>
              <br />
              <p>
                • Twitter Datenschutzrichtlinie: https://twitter.com/de/privacy
              </p>
              <br />
              <h3> LinkedIn Datenschutzerklärung </h3>
              <br />
              <p>
                Wir nutzen auf unserer Webseite Social-Plug-ins des
                Social-Media-Netzwerks LinkedIn, der Firma LinkedIn Corporation,
                2029 Stierlin Court, Mountain View, CA 94043, USA. Bei den
                Social-Plug-ins kann es sich um Feeds, das Teilen von Inhalten
                oder um die Verlinkung zu unserer LinkedIn-Seite handeln. Die
                Social-Plug-ins sind eindeutig mit dem bekannten LinkedIn-Logo
                gekennzeichnet und erlauben beispielsweise interessante Inhalte
                direkt über unsere Webseite zu teilen. Für den Europäischen
                Wirtschaftsraum und die Schweiz ist die Firma LinkedIn Ireland
                Unlimited Company Wilton Place in Dublin für die
                Datenverarbeitung verantwortlich. Durch die Einbettung solcher
                Plug-ins können Daten an LinkedIn versandt, gespeichert und dort
                verarbeitet werden. In dieser Datenschutzerklärung wollen wir
                Sie informieren, um welche Daten es sich handelt, wie das
                Netzwerk diese Daten verwendet und wie Sie die Datenspeicherung
                verwalten bzw. unterbinden können.
              </p>
              <br />
              <h3>Was ist LinkedIn?</h3>
              <br />
              <p>
                LinkedIn ist das größte soziale Netzwerk für Geschäftskontakte.
                Anders als beispielsweise bei Facebook konzentriert sich das
                Unternehmen ausschließlich auf den Aufbau geschäftlicher
                Kontakte. Unternehmen können auf der Plattform Dienstleistungen
                und Produkte vorstellen und Geschäftsbeziehungen knüpfen. Viele
                Menschen verwenden LinkedIn auch für die Jobsuche oder um selbst
                geeignete Mitarbeiter oder Mitarbeiterinnen für die eigene Firma
                zu finden. Allein in Deutschland zählt das Netzwerk über 11
                Millionen Mitglieder. In Österreich sind es etwa 1,3 Millionen.
              </p>
              <br />
              <h3>Warum verwenden wir LinkedIn auf unserer Webseite?</h3>
              <br />
              <p>
                Wir wissen wie beschäftigt Sie sind. Da kann man nicht alle
                Social-Media-Kanäle einzeln verfolgen. Auch wenn es sich, wie in
                unserem Fall, lohnen würde. Denn immer wieder posten wir
                interessante News oder Berichte, die es wert sind, verbreitet zu
                werden. Darum haben wir auf unserer Webseite die Möglichkeit
                geschaffen, interessante Inhalte direkt auf LinkedIn zu teilen
                bzw. direkt auf unsere LinkedIn-Seite zu verweisen. Wir
                betrachten eingebaute Social-Plug-ins als erweiterten Service
                auf unserer Webseite. Die Daten, die LinkedIn sammelt, helfen
                uns zudem mögliche Werbemaßnahmen nur Menschen zu zeigen, die
                sich für unser Angebot interessieren.
              </p>
              <br />
              <h3> Welche Daten werden von LinkedIn gespeichert?</h3>
              <br />
              <p>
                Nur durch die bloße Einbindung der Social-Plug-ins speichert
                LinkedIn keine persönlichen Daten. LinkedIn nennt diese Daten,
                die durch Plug-ins generiert werden, passive Impressionen. Wenn
                Sie aber auf ein Social-Plug-in klicken, um beispielsweise
                unsere Inhalte zu teilen, speichert die Plattform
                personenbezogene Daten als sogenannte „aktive Impressionen“. Und
                zwar unabhängig, ob Sie ein LinkedIn-Konto haben oder nicht.
                Falls Sie angemeldet sind, werden die erhobenen Daten Ihrem
                Konto zugeordnet. Ihr Browser stellt eine direkte Verbindung zu
                den Servern von LinkedIn her, wenn Sie mit unseren Plug-ins
                interagieren. So protokolliert das Unternehmen verschiedene
                Nutzungsdaten. Neben Ihrer IP-Adresse können das beispielsweise
                Anmeldungsdaten, Gerätinformationen oder Infos über Ihren
                Internet- bzw. Mobilfunkanbieter sein. Wenn Sie LinkedIn-Dienste
                über Ihr Smartphone aufrufen, kann auch Ihr Standort (nachdem
                Sie das erlaubt haben) ermittelt werden. LinkedIn kann diese
                Daten in „gehashter“ Form auch an dritte Werbetreibende
                weitergeben. Hashing meint, ein Datensatz wird in eine
                Zeichenkette verwandelt. Dadurch kann man die Daten so
                verschlüsseln, dass Personen nicht mehr identifiziert werden
                können. Die meisten Daten zu Ihrem Userverhalten werden in
                Cookies gespeichert. Das sind kleine Text-Dateien, die meist in
                Ihrem Browser gesetzt werden. Weiters kann LinkedIn aber auch
                Web Beacons, Pixel-Tags, Anzeige-Tags und andere
                Geräteerkennungen benutzen. Diverse Tests zeigen auch welche
                Cookies gesetzt werden, wenn ein User mit einem Social-Plug-in
                interagiert. Die gefundenen Daten können keinen
                Vollständigkeitsanspruch erheben und dienen lediglich als
                Beispiel. Die folgenden Cookies wurden gesetzt, ohne bei
                LinkedIn angemeldet zu sein:
              </p>
              <br />
              <p>Name: bcookie</p>
              <p>Wert: =2&34aab2aa-2ae1-4d2a-8baf-c2e2d7235c16311230666-</p>
              <p>
                Verwendungszweck: Das Cookie ist ein sogenanntes
                „Browser-ID-Cookie“ und speichert folglich Ihre
                Identifikationsnummer (ID).
              </p>
              <p>Ablaufdatum: Nach 2 Jahren</p>
              <br />
              <p>Name: lang</p>
              <p>Wert: v=2&lang=de-de</p>
              <p>
                Verwendungszweck: Dieses Cookie speichert Ihre voreingestellte
                bzw. bevorzugte Sprache.
              </p>
              <p>Ablaufdatum: nach Sitzungsende </p>
              <br />
              <p>Name: lidc</p>
              <p>Wert: 1818367:t=1571904767:s=AQF6KNnJ0G311230666…</p>
              <p>
                Verwendungszweck: Dieses Cookie wird zum Routing verwendet.
                Routing zeichnet die Wege auf, wie Sie zu LinkedIn gekommen sind
                und wie Sie dort durch die Webseite navigieren.
              </p>
              <p>Ablaufdatum: nach 24 Stunden</p>
              <br />
              <p>Name: rtc</p>
              <p>Wert: kt0lrv3NF3x3t6xvDgGrZGDKkX</p>
              <p>
                Verwendungszweck: Zu diesem Cookie konnten keine näheren
                Informationen in Erfahrung gebracht werden.
              </p>
              <p>Ablaufdatum: nach 2 Minuten</p>
              <br />
              <p>Name: JSESSIONID</p>
              <p>Wert: ajax:3112306662900777718326218137</p>
              <p>
                Verwendungszweck: Es handelt sich hier um ein Session-Cookie,
                das LinkedIn verwendet, um anonyme Benutzersitzungen durch den
                Server aufrecht zu erhalten.
              </p>
              <p>Ablaufdatum: nach Sitzungsende</p>
              <br />
              <p>Name: bscookie </p>
              <p>Wert: “v=1&201910230812… </p>
              <p>
                Verwendungszweck: Bei diesem Cookie handelt es sich um ein
                Sicherheits-Cookie. LinkedIn beschreibt es als
                Secure-Browser-ID-Cookie.
              </p>
              <p> Ablaufdatum: nach 2 Jahren</p>
              <br />
              <p>Name: fid</p>
              <p>Wert: AQHj7Ii23ZBcqAAAA…</p>
              <p>
                Verwendungszweck: Zu diesem Cookie konnten keine näheren
                Informationen gefunden werden.
              </p>
              <p>Ablaufdatum: nach 7 Tagen</p>
              <br />
              <p>
                <b>Anmerkung:</b> LinkedIn arbeitet auch mit Drittanbietern
                zusammen. Darum haben wir bei unserem Test auch die beiden
                Google-Analytics-Cookies _ga und _gat erkannt.
              </p>
              <br />
              <h3>Wie lange und wo werden die Daten gespeichert?</h3>
              <br />
              <p>
                Grundsätzlich behaltet LinkedIn Ihre personenbezogenen Daten so
                lange, wie es das Unternehmen als nötig betrachtet, um die
                eigenen Dienste anzubieten. LinkedIn löscht aber Ihre
                personenbezogenen Daten, wenn Sie Ihr Konto löschen. In manchen
                Ausnahmefällen behaltet LinkedIn selbst nach Ihrer Kontolöschung
                einige Daten in zusammengefasster und anonymisierter Form.
                Sobald Sie Ihr Konto löschen, können andere Personen Ihre Daten
                innerhalb von einem Tag nicht mehr sehen. LinkedIn löscht die
                Daten grundsätzlich innerhalb von 30 Tagen. LinkedIn behält
                allerdings Daten, wenn es aus rechtlicher Pflicht notwendig ist.
                Daten, die keinen Personen mehr zugeordnet werden können,
                bleiben auch nach Schließung des Kontos gespeichert. Die Daten
                werden auf verschiedenen Servern in Amerika und vermutlich auch
                in Europa gespeichert.
              </p>
              <br />
              <h3>
                Wie kann ich meine Daten löschen bzw. die Datenspeicherung
                verhindern?
              </h3>
              <br />
              <p>
                Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten
                zuzugreifen und sie auch zu löschen. In Ihrem LinkedIn-Konto
                können Sie Ihre Daten verwalten, ändern und löschen. Zudem
                können Sie von LinkedIn auch eine Kopie Ihrer personenbezogenen
                Daten anfordern. So greifen Sie auf die Kontodaten in Ihrem
                LinkedIn-Profil zu: Klicken Sie in LinkedIn auf Ihr Profilsymbol
                und wählen Sie die Rubrik „Einstellungen und Datenschutz“.
                Klicken Sie nun auf „Datenschutz“ und dann im Abschnitt „So
                verwendet LinkedIn Ihre Daten auf „Ändern“. In nur kurzer Zeit
                können Sie ausgewählte Daten zu Ihrer Web-Aktivität und Ihrem
                Kontoverlauf herunterladen. Sie haben auch in Ihrem Browser die
                Möglichkeit, die Datenverarbeitung durch LinkedIn zu
                unterbinden. Wie oben bereits erwähnt, speichert LinkedIn die
                meisten Daten über Cookies, die in Ihrem Browser gesetzt werden.
                Diese Cookies können Sie verwalten, deaktivieren oder löschen.
                Je nachdem, welchen Browser Sie haben, funktioniert die
                Verwaltung etwas anders. Die Anleitungen der gängigsten Browser
                finden Sie hier:
              </p>
              <br />
              <p>Chrome: Cookies in Chrome löschen, aktivieren und verwalten</p>
              <br />
              <p> Safari: Verwalten von Cookies und Websitedaten mit Safari </p>
              <br />
              <p>
                Firefox: Cookies löschen, um Daten zu entfernen, die Websites
                auf Ihrem Computer abgelegt haben
              </p>
              <br />
              <p> Internet Explorer: Löschen und Verwalten von Cookies </p>
              <br />
              <p> Microsoft Edge: Löschen und Verwalten von Cookies</p>
              <br />
              <p>
                Sie können auch grundsätzlich Ihren Browser dahingehend
                einrichten, dass Sie immer informiert werden, wenn ein Cookie
                gesetzt werden soll. Dann können Sie immer individuell
                entscheiden, ob Sie das Cookie zulassen wollen oder nicht.
                LinkedIn ist aktiver Teilnehmer des EU-U.S. Privacy Shield
                Frameworks. Dieses Framework stellt eine korrekte
                Datenübertragung zwischen den USA und der Europäischen Union
                sicher.
                Unter https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0 erfahren
                Sie mehr darüber. Wir haben versucht, Ihnen die wichtigsten
                Informationen über die Datenverarbeitung durch LinkedIn
                näherzubringen. Auf
                https://www.linkedin.com/legal/privacy-policy erfahren Sie noch
                mehr über die Datenverarbeitung des Social-Media-Netzwerks
                LinkedIn.
              </p>
              <br />
              <h3> Stripe Datenschutzerklärung</h3>
              <br />
              <p>
                Wir verwenden auf unserer Website ein Zahlungstool des
                amerikanischen Technologieunternehmens und Online-Bezahldienstes
                Stripe. Für Kunden innerhalb von der EU ist Stripe Payments
                Europe (Europe Ltd., 1 Grand Canal Street Lower, Grand Canal
                Dock, Dublin, Irland) verantwortlich. Das heißt, wenn Sie sich
                für Stripe als Zahlungsart entscheiden, wird Ihre Zahlung über
                Stripe Payments abgewickelt. Dabei werden Daten, die für den
                Zahlungsvorgang nötig sind, an Stripe weitergeleitet und
                gespeichert. In dieser Datenschutzerklärung geben wir Ihnen
                einen Überblick über diese Datenverarbeitung und Speicherung
                durch Stripe und erklären, warum wir Stripe auf unserer Website
                verwenden.
              </p>
              <br />
              <h3>Was ist Stripe?</h3>
              <br />
              <p>
                Das Technologieunternehmen Stripe bietet Zahlungslösungen für
                Online-Bezahlungen an. Mit Stripe ist es möglich Kredit- und
                Debitkartenzahlungen in unserem Webshop zu akzeptieren. Stripe
                übernimmt den gesamten Zahlungsvorgang. Ein großer Vorteil von
                Stripe ist etwa, dass Sie während des Zahlungsvorgangs nie
                unsere Website bzw. den Shop verlassen müssen und die
                Zahlungsabwicklung sehr schnell erfolgt.
              </p>
              <br />
              <h3> Warum verwenden wir Stripe für unsere Website?</h3>
              <br />
              <p>
                Wir wollen natürlich mit unserer Website und unserem
                eingebundenen Onlineshop das bestmögliche Service bietet, damit
                Sie sich auf unserer Seite wohl fühlen und unsere Angebote
                nutzen. Wir wissen, dass Ihre Zeit kostbar ist und daher
                speziell Zahlungsabwicklungen schnell und reibungslos
                funktionieren müssen. Neben unseren anderen Zahlungsanbietern
                haben wir mit Stripe einen Partner gefunden, der eine sichere
                und schnelle Zahlungsabwicklung gewährleistet.
              </p>
              <br />
              <p>
                Welche Daten werden von Stripe gespeichert? Wenn Sie sich für
                Stripe als Zahlungsart entscheiden, werden auch personenbezogene
                Daten von Ihnen an Stripe übermittelt und dort gespeichert.
                Dabei handelt es sich um Transaktionsdaten. Zu diesen Daten
                zählen etwa die Zahlungsmethode (also Kreditkarten- Debitkarten
                oder Kontonummer), Bankleitzahl, Währung, der Betrag und das
                Datum der Zahlung. Bei einer Transaktion kann weiters Ihr Name,
                Ihre E-Mail-Adresse, Ihre Rechnungs- oder Versandadresse und
                manchmal auch Ihr Transaktionsverlauf übermittelt werden. Diese
                Daten sind zur Authentifizierung nötig. Weiters kann Stripe zur
                Betrugsabwehr, der Finanzberichterstattung und um die eigenen
                Dienste vollständig anbieten zu können, auch neben technischen
                Daten zu Ihrem Gerät (wie IP-Adresse)  Name, Adresse,
                Telefonnummer und Ihr Land erfassen. Stripe verkauft keine Ihrer
                Daten an unabhängige Dritte, wie etwa Marketingagenturen oder
                andere Unternehmen, die mit dem Stripe-Unternehmen nichts zu tun
                haben. Die Daten können aber etwa an interne Abteilungen, einer
                beschränkten Anzahl externer Stripe-Partner oder zur Einhaltung
                gesetzlicher Vorschriften weitergeleitet werden. Stripe
                verwendet zur Erfassung von Daten auch Cookies. Hier finden Sie
                eine Auswahl an Cookies, die Stripe während des
                Zahlungsprozesses setzen kann:
              </p>
              <br />
              <p>Name: m </p>
              <p>Wert: edd716e9-d28b-46f7-8a55-e05f1779e84e040456311230666-5</p>
              <p>
                Verwendungszweck: Dieses Cookie erscheint, wenn Sie die
                Zahlungsmethode auswählen. Es speichert und erkennt, ob Sie über
                einen PC, ein Tablet oder ein Smartphone auf unsere Website
                zugreifen.
              </p>
              <p>Ablaufdatum: nach 2 Jahren </p>
              <br />
              <p>Name: __stripe_mid</p>
              <p>Wert: fc30f52c-b006-4722-af61-a7419a5b8819875de9311230666-1</p>
              <p>
                Verwendungszweck: Um eine Kreditkartentransaktion durchführen zu
                können, wird dieses Cookie benötigt. Dazu speichert das Cookie
                Ihre Sitzungs-ID.
              </p>
              <p>Ablaufdatum: nach einem Jahr</p>
              <br />
              <p>Name: __stripe_sid</p>
              <p>Wert: 6fee719a-c67c-4ed2-b583-6a9a50895b122753fe </p>
              <p>
                Verwendungszweck: Auch dieses Cookie speichert Ihre ID und wird
                für den Zahlungsprozess auf unserer Website durch Stripe
                verwendet.
              </p>
              <p> Ablaufdatum: nach Ablauf der Sitzung</p>
              <br />
              <h3>Wie lange und wo werden die Daten gespeichert?</h3>
              <br />
              <p>
                Personenbezogene Daten werden grundsätzlich für die Dauer der
                Diensterbringung gespeichert. Das heißt, die Daten werden so
                lange gespeichert, bis wir die Zusammenarbeit mit Stripe
                auflösen. Um allerdings die gesetzlichen und behördlichen
                Pflichten zu erfüllen kann Stripe auch über die Dauer der
                Diensterbringung personenbezogene Daten speichern. Da Stripe ein
                weltweit tätiges Unternehmen ist, können die Daten auch in jedem
                Land, wo Stripe Dienstleistungen anbietet, gespeichert werden.
                So können auch Daten außerhalb Ihres Landes, zum Beispiel in den
                USA gespeichert werden.
              </p>
              <br />
              <h3>
                Wie kann ich meine Daten löschen bzw. die Datenspeicherung
                verhindern?
              </h3>
              <br />
              <p>
                Stripe ist nach wie vor Teilnehmer des EU-U.S. Privacy Shield
                Framework, wodurch bis 16. Juli 2020 der korrekte und sichere
                Datentransfer persönlicher Daten geregelt wurde. Nachdem der
                Europäische Gerichtshof das Abkommen für ungültig erklärt hat,
                stützt sich das Unternehmen nun nicht mehr auf dieses Abkommen,
                handelt aber noch nach den Prinzipien des Privacy Shields. Sie
                haben immer das Recht auf Auskunft, Berichtigung und Löschung
                Ihrer personenbezogenen Daten. Bei Fragen können Sie auch
                jederzeit das Stripe-Team über
                https://support.stripe.com/contact/email kontaktieren. Cookies,
                die Stripe für ihre Funktionen verwenden, können Sie in Ihrem
                Browser löschen, deaktivieren oder verwalten. Je nachdem welchen
                Browser Sie verwenden, funktioniert dies auf unterschiedliche
                Art und Weise. Bitte beachten Sie aber, dass dann eventuell der
                Zahlungsvorgang nicht mehr funktioniert. Die folgenden
                Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
              </p>
              <br />
              <p>Chrome: Cookies in Chrome löschen, aktivieren und verwalten</p>
              <br />
              <p> Safari: Verwalten von Cookies und Websitedaten mit Safari</p>
              <br />
              <p>
                Firefox: Cookies löschen, um Daten zu entfernen, die Websites
                auf Ihrem Computer abgelegt haben
              </p>
              <br />
              <p>Internet Explorer: Löschen und Verwalten von Cookies</p>
              <br />
              <p>Microsoft Edge: Löschen und Verwalten von Cookies</p>
              <br />
              <p>
                Wir haben Ihnen nun einen allgemeinen Überblick über die
                Verarbeitung und Speicherung der Daten durch Stripe gegeben.
                Wenn Sie noch mehr und noch genauere Informationen einholen
                wollen, dient Ihnen die ausführliche Stripe-Datenschutzerklärung
                unter https://stripe.com/at/privacy als gute Quelle.
              </p>
              <br />
              <h3>BootstrapCDN Datenschutzerklärung</h3>
              <br />
              <p>
                Um Ihnen all unsere einzelnen Webseiten (Unterseiten unserer
                Website) auf allen Geräten schnell und sicher ausliefern zu
                können, nutzen wir das Content Delivery Network (CDN)
                BootstrapCDN des amerikanischen Software-Unternehmens StackPath,
                LLC 2012 McKinney Ave. Suite 1100, Dallas, TX 75201, USA. Ein
                Content Delivery Network (CDN) ist ein Netzwerk regional
                verteilter Server, die über das Internet miteinander verbunden
                sind. Durch dieses Netzwerk können Inhalte, speziell sehr große
                Dateien, auch bei großen Lastspitzen schnell ausgeliefert
                werden. BootstrapCDN funktioniert so, dass sogenannte
                JavaScript-Bibliotheken an Ihren Browser ausgeliefert werden.
                Lädt nun Ihr Browser eine Datei vom BootstrapCDN herunter, wird
                Ihre IP-Adresse während der Verbindung zum Bootstrap-CDN-Server
                an das Unternehmen StockPath übermittelt. StackPath erwähnt auch
                in der hauseigenen Datenschutzerklärung, dass das Unternehmen
                aggregierte und anonymisierte Daten von diversen Diensten (wie
                BootstrapCDN) für die Erweiterung der Sicherung und für andere
                StackPath-Dienste und Clients verwenden. All diese Daten können
                aber keine Person identifizieren. Wenn Sie diese
                Datenübertragung unterbinden wollen, können Sie einen
                JavaScript-Blocker (siehe beispielsweise https://noscript.net/)
                installieren oder in Ihrem Browser die Ausführung von
                JavaScript-Codes deaktivieren. Bitte beachten Sie aber, dass
                dadurch die Website nicht mehr das gewohnte Service (wie etwa
                schnelle Ladegeschwindigkeit) bieten kann. StackPath ist aktiver
                Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der
                korrekte und sichere Datentransfer persönlicher Daten geregelt
                wird. Mehr Informationen dazu finden Sie auf
                https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active.
                Mehr Informationen zum Datenschutz bei StackPath bzw.
                BootstrapCDN finden Sie auf
                https://www.bootstrapcdn.com/privacy-policy/.
              </p>
              <br />
              <p>
                Quelle: Erstellt mit dem Datenschutz Generator von AdSimple in
                Kooperation mit warkly.de
              </p>
            </TextWrapper>
          </Column2>
        </InfoRow>
      </InfoWrapper>
    </InfoContainer>
  );
}

export default PrivacyPolicyGerman;

import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
} from "../InfoSection/InfoElements";

import { TextWrapper } from "./LegalStuffElements";

const PrivacyPolicyEnglish = () => {
  return (
    <InfoContainer className="info_container_class">
      <InfoWrapper className="info_wrapper_class">
        <InfoRow className="info_row_class">
          <Column1>
            <TextWrapper>
              <h1>Privacy policy</h1>
              <br />
              <h2>Privacy</h2>
              <br />
              <p>
                We have drawn up this data protection declaration (version
                12.11.2020-311230666) to explain to you, in accordance with the
                provisions of the basic data protection regulation (EU)
                2016/679, what information we collect, how we use data and what
                decision-making options you have as a visitor to this website.
                Unfortunately, it is in the nature of things that these
                explanations sound very technical, but we have tried to describe
                the most important things as simply and clearly as possible.
              </p>
              <br />
              <h3>Automatic data storage </h3>
              <br />
              <p>
                When you visit websites today, certain information is
                automatically created and stored, including on this website.
                When you visit our website as you are visiting it right now, our
                web server (the computer on which this website is stored)
                automatically stores information such as - the address (URL) of
                the accessed web page - Browser and browser version - the
                operating system used - the address (URL) of the previously
                visited page (referrer URL) - the host name and IP address of
                the device being accessed - Date and time in files (web server
                log files). Usually web server log files are stored for two
                weeks and then automatically deleted. We do not pass on this
                data, but cannot exclude the possibility that this data may be
                viewed in the event of illegal behavior.
              </p>
              <br />
              <h3>Cookies</h3>
              <br />
              <p>
                Our website uses HTTP cookies to store user-specific data. In
                the following we explain what cookies are and why they are used
                so that you can better understand the following privacy policy.
              </p>
              <br />
              <h3>What exactly are cookies?</h3>
              <br />
              <p>
                Whenever you surf the Internet, you are using a browser.
                Well-known browsers include Chrome, Safari, Firefox, Internet
                Explorer and Microsoft Edge. Most websites store small text
                files in your browser. These files are called cookies. One thing
                cannot be dismissed: Cookies are really useful little helpers.
                Almost all websites use cookies. More precisely, they are HTTP
                cookies, since there are other cookies for other applications.
                HTTP cookies are small files that are stored on your computer by
                our website. These cookie files are automatically stored in the
                cookie folder, quasi the "brain" of your browser. A cookie
                consists of a name and a value. When defining a cookie, one or
                more attributes must also be specified. Cookies store certain
                user data about you, such as language or personal page settings.
                When you return to our site, your browser transmits the
                "user-related" information back to our site. Thanks to the
                cookies, our website knows who you are and offers you your usual
                default settings. In some browsers, each cookie has its own
                file, in others, such as Firefox, all cookies are stored in a
                single file. There are both first-party cookies and third-party
                cookies. First-party cookies are created directly by our site,
                third-party cookies are created by partner sites (e.g. Google
                Analytics). Each cookie is unique because each cookie stores
                different data. The expiration time of a cookie also varies from
                a few minutes to a few years. Cookies are not software programs
                and do not contain viruses, trojans or other "malware". Cookies
                also cannot access information on your PC. For example, cookie
                data may look like this:
                <p />
                <br />
                <p>Name: ga</p>
                <p>Expiration period: 2 years</p>
                <p>Usage: differentiation of website visitors</p>
                <p>value: GA1.2.1326744211.152311230666</p>
                <br />
                <p>A browser should support the following minimum sizes:</p>
                <br />
                <p>A cookie should be able to contain at least 4096 bytes </p>
                <br />
                <p>
                  At least 50 cookies should be able to be stored per domain
                </p>
                <br />
                <p>
                  A total of at least 3000 cookies should be able to be stored
                </p>
              </p>
              <br />
              <h3>What types of cookies are there? </h3>
              <br />
              <p>
                The question which cookies we use in particular depends on the
                services used and is clarified in the following sections of the
                privacy policy. At this point we would like to briefly discuss
                the different types of HTTP cookies. One can distinguish between
                4 types of cookies:
              </p>
              <br />
              <h4>Absolutely necessary cookies </h4>
              <br />
              <p>
                These cookies are necessary to ensure basic functions of the
                website. For example, these cookies are needed when a user adds
                a product to the shopping cart, then continues surfing on other
                pages and only goes to checkout later. These cookies do not
                delete the shopping cart, even if the user closes his browser
                window.
              </p>
              <br />
              <h4>Functional Cookies</h4>
              <br />
              <p>
                These cookies collect information about user behavior and
                whether the user receives any error messages. These cookies are
                also used to measure the loading time and the behavior of the
                website in different browsers.
              </p>
              <br />
              <h4>Target-oriented cookies</h4>
              <br />
              <p>
                These cookies ensure a better user experience. For example,
                entered locations, font sizes or form data are stored.
              </p>
              <br />
              <h4>Advertising cookies</h4>
              <br />
              <p>
                These cookies are also called targeting cookies. They are used
                to deliver customized advertising to the user. This can be very
                practical, but also very annoying. Usually the first time you
                visit a website, you will be asked which of these types of
                cookies you want to allow. And of course this decision is also
                stored in a cookie.
              </p>
              <br />
              <h3>How can I delete cookies?</h3>
              <br />
              <p>
                How and whether you want to use cookies is up to you. Regardless
                of the service or website from which the cookies originate, you
                always have the option of deleting cookies, allowing them only
                partially or deactivating them. For example, you can block
                third-party cookies, but allow all other cookies. If you want to
                find out which cookies are stored in your browser, if you want
                to change or delete cookie settings, you can find this in your
                browser settings:
              </p>
              <br />
              <p>Chrome: Delete, activate and manage cookies in Chrome</p>
              <br />
              <p>Safari: Manage cookies and website data with Safari</p>
              <br />
              <p>
                Firefox: delete cookies to remove data that websites have placed
                on your computer
              </p>
              <br />
              <p>Internet Explorer: Delete and manage cookies</p>
              <br />
              <p>Microsoft Edge: Delete and manage cookies</p>
              <br />
              <p>
                If you do not wish to receive cookies, you can set your browser
                to notify you whenever a cookie is set. In this way, you can
                decide for each individual cookie whether or not you wish to
                accept it. The procedure varies depending on the browser. The
                best thing to do is to look for the instructions in Google with
                the search term "Delete cookies Chrome" or "Deactivate cookies
                Chrome" in case of a Chrome browser or replace the word "Chrome"
                with the name of your browser, e.g. Edge, Firefox, Safari.
              </p>
              <br />
              <h3> What about my privacy?</h3>
              <br />
              <p>
                Since 2009 there are the so-called "cookie guidelines". This
                states that the storage of cookies requires your consent. Within
                the EU countries, however, there are still very different
                reactions to these guidelines. In Germany the cookie guidelines
                were not implemented as national law. Instead, the
                implementation of this directive was largely carried out in § 15
                para.3 of the German Telemedia Act (TMG). If you want to know
                more about cookies and are not afraid of technical
                documentation, we recommend https://tools.ietf.org/html/rfc6265,
                the Request for Comments of the Internet Engineering Task Force
                (IETF) called "HTTP State Management Mechanism".
              </p>
              <br />
              <h3>Storage of Personal Data</h3>
              <br />
              <p>
                Personal information that you submit to us electronically on
                this website, such as your name, e-mail address, postal address
                or other personal information when submitting a form or comments
                on the blog, together with the time and IP address, will only be
                used by us for the purpose stated, kept secure and not disclosed
                to third parties. Thus, we use your personal data only for
                communication with those visitors who expressly request contact
                and for the processing of the services and products offered on
                this website. We will not pass on your personal data without
                your consent, but we cannot exclude the possibility that this
                data may be viewed in the event of unlawful behaviour. If you
                send us personal data by e-mail - thus off this website - we
                cannot guarantee secure transmission and protection of your
                data. We recommend that you never send confidential data by
                e-mail without encryption. According to article 6 paragraph 1 a
                DSGVO (legality of processing), the legal basis is that you give
                us your consent to process the data you have entered. You can
                revoke this consent at any time - an informal e-mail is
                sufficient, you will find our contact details in the imprint.
              </p>
              <br />
              <h3>
                Rights in accordance with the basic data protection ordinance
              </h3>
              <br />
              <p>
                In accordance with the provisions of the DSGVO, you are
                basically entitled to the following rights: - Right of
                rectification (Article 16 DSGVO) - Right of deletion ("right to
                be forgotten") (Article 17 DSGVO) - Right to restrict processing
                (Article 18 DSGVO) - Right of notification - Obligation to
                notify in connection with the correction or deletion of personal
                data or the restriction of processing (Article 19 DPA) - Right
                to data transferability (Article 20 DSGVO) - Right of objection
                (Article 21 DSGVO) - Right not to be subject to a decision based
                solely on automated processing, including profiling (Article 22
                DPA) If you believe that the processing of your data violates
                data protection law or your data protection rights have
                otherwise been violated in any way, you can contact the Federal
                Commissioner for Data Protection and Freedom of Information
                (BfDI).
              </p>
              <br />
              <h3>TLS encryption with https </h3>
              <br />
              <p>
                We use https to transmit data tap-proof on the Internet (data
                protection through technology design article 25 paragraph 1
                DSGVO). By using TLS (Transport Layer Security), an encryption
                protocol for secure data transmission on the Internet, we can
                ensure the protection of confidential data. You can recognize
                the use of this data transmission security by the small lock
                symbol in the upper left corner of the browser and the use of
                the scheme https (instead of http) as part of our internet
                address.
              </p>
              <br />
              <h3>Google Fonts Privacy Policy</h3>
              <br />
              <p>
                On our website we use Google Fonts. These are the "Google fonts"
                of the company Google Inc. For the European area the company
                Google Ireland Limited (Gordon House, Barrow Street Dublin 4,
                Ireland) is responsible for all Google services. To use Google
                fonts, you do not need to log in or provide a password.
                Furthermore, no cookies are stored in your browser. The files
                (CSS, fonts) are requested via the Google domains
                fonts.googleapis.com and fonts.gstatic.com. According to Google,
                the requests for CSS and fonts are completely separate from all
                other Google services. If you have a Google Account, you do not
                need to worry about your Google Account information being
                submitted to Google while using Google Fonts. Google records the
                use of CSS (Cascading Style Sheets) and the fonts used and
                stores this information securely. We will see in detail how the
                data storage looks exactly.
              </p>
              <br />
              <h3>What are Google Fonts?</h3>
              <br />
              <p>
                In former times Google Fonts were also called Google Web Fonts.
                This is an interactive directory with over 800 fonts, which
                Google provides free of charge. With Google Fonts you could use
                fonts without uploading them to your own server. But to prevent
                any information transfer to Google servers, we downloaded the
                fonts to our server. In this way, we act in accordance with data
                protection laws and do not send any data to Google Fonts. Unlike
                other web fonts, Google allows us unlimited access to all fonts.
                This means that we have unlimited access to a sea of fonts and
                can thus get the best out of our website. More about Google
                Fonts and other questions can be found at
                https://developers.google.com/fonts/faq?tid=311230666.
              </p>
              <br />
              <h3> Why do we use Google Fonts on our website?</h3>
              <br />
              <p>
                With Google Fonts we can use fonts on our own website, but we do
                not have to upload them to our own server. Google Fonts is an
                important component to keep the quality of our website high. All
                Google fonts are automatically optimized for the web and this
                saves data volume and is a great advantage especially for the
                use with mobile devices. If you visit our site, the low file
                size ensures a fast loading time. Furthermore, Google Fonts are
                secure web fonts. Different image synthesis systems (rendering)
                in different Browsers, operating systems and mobile devices can
                lead to errors. Such errors can visually distort some texts or
                entire web pages. Thanks to the fast Content Delivery Network
                (CDN), there are no cross-platform problems with Google Fonts.
                Google Fonts supports all major browsers (Google Chrome, Mozilla
                Firefox, Apple Safari, Opera) and works reliably on most modern
                mobile operating systems, including Android 2.2+ and iOS 4.2+
                (iPhone, iPad, iPod). So we use Google Fonts to make our entire
                online service as beautiful and consistent as possible.
              </p>
              <br />
              <h3>What data does Google store?</h3>
              <br />
              <p>
                When you visit our website, the fonts are reloaded via a Google
                server. This external call transfers data to the Google servers.
                In this way Google also recognizes that you or your IP address
                are visiting our website. The Google Fonts API was developed to
                reduce the use, storage and collection of end user data to what
                is necessary for a proper provision of fonts. By the way, API
                stands for "Application Programming Interface" and serves, among
                other things, as a data transmitter in the software sector.
                Google Fonts securely stores CSS and font requests at Google and
                is therefore protected. Through the collected usage figures,
                Google can determine how well the individual fonts are received.
                Google publishes the results on internal analysis pages, such as
                Google Analytics. Google also uses data from its own web crawler
                to determine which websites use Google fonts. This data is
                published in the BigQuery database of Google Fonts.
                Entrepreneurs and developers use Google's BigQuery web service
                to examine and move large amounts of data. However, it should
                also be noted that each Google Font request automatically sends
                information such as language settings, IP address, browser
                version, browser screen resolution and browser name to the
                Google servers. Whether this data is also stored cannot be
                clearly determined or is not clearly communicated by Google.
              </p>
              <br />
              <h3>How long and where is the data stored?</h3>
              <br />
              <p>
                Google stores requests for CSS assets for one day on its
                servers, which are mainly located outside the EU. This allows us
                to use the fonts with the help of a Google style sheet. A
                stylesheet is a style template that allows you to easily and
                quickly change, for example, the design or font of a web page.
                The font files are stored at Google for one year. Google's goal
                is to improve the loading time of web pages. If millions of web
                pages link to the same fonts, they are cached after the first
                visit and reappear immediately on all other web pages visited
                later. Sometimes Google updates font files to reduce file size,
                increase language coverage and improve design.
              </p>
              <br />
              <h3>How can I delete my data or prevent data storage?</h3>
              <br />
              <p>
                The data that Google stores for a day or a year cannot be
                deleted easily. The data is automatically transferred to Google
                when the page is viewed. To delete this data prematurely, you
                must contact Google support at
                https://support.google.com/?hl=de&tid=311230666. In this case
                you only prevent data storage if you do not visit our site.
                Unlike other web fonts, Google allows us unlimited access to all
                fonts. So we have unlimited access to a sea of fonts and can
                thus get the best out of our website. You can find more
                information about Google Fonts and other questions at
                https://developers.google.com/fonts/faq?tid=311230666. There,
                Google deals with data protection issues, but really detailed
                information about data storage is not included. It is relatively
                difficult to get really detailed information about stored data
                from Google. Which data is basically collected by Google and
                what this data is used for can also be read at
                https://www.google.com/intl/de/policies/privacy/.
              </p>
            </TextWrapper>
          </Column1>
          <Column2>
            <TextWrapper>
              <h3>Google Fonts Local Privacy Policy</h3>
              <br />
              <p>
                On our website we use Google Fonts from Google Inc. The company
                Google Ireland Limited (Gordon House, Barrow Street Dublin 4,
                Ireland) is responsible for the European area. We have
                integrated the Google fonts locally, i.e. on our web server -
                not on the servers of Google. This means that there is no
                connection to Google servers and therefore no data transfer or
                storage.
              </p>
              <br />
              <h3>Embedded Social Media Elements Privacy Policy</h3>
              <br />
              <p>
                We integrate elements of social media services on our website to
                display images, videos and text. By visiting pages that display
                these elements, data is transferred from your browser to the
                respective social media service and stored there. We have no
                access to this data. The following links will take you to the
                pages of the respective social media services where it is
                explained how they handle your data: - Instagram Privacy Policy:
                https://help.instagram.com/519522125107875 - For YouTube the
                Google Privacy Policy applies:
                https://policies.google.com/privacy?hl=de - Facebook data
                policy: https://www.facebook.com/about/privacy - Twitter Privacy
                Policy: https://twitter.com/de/privacy
              </p>
              <br />
              <h3>LinkedIn Privacy Policy</h3>
              <br />
              <p>
                On our website we use social plug-ins from the social media
                network LinkedIn, the company LinkedIn Corporation, 2029
                Stierlin Court, Mountain View, CA 94043, USA. The social
                plug-ins can be feeds, content sharing or linking to our
                LinkedIn site. The social plug-ins are clearly marked with the
                well-known LinkedIn logo and allow, for example, to share
                interesting content directly through our website. For the
                European Economic Area and Switzerland, LinkedIn Ireland
                Unlimited Company Wilton Place in Dublin is responsible for data
                processing. By embedding such plug-ins, data can be sent to
                LinkedIn, stored and processed there. In this privacy policy we
                would like to inform you what data is involved, how the network
                uses this data and how you can manage or stop the data storage.
              </p>
              <br />
              <h3> What is LinkedIn?</h3>
              <br />
              <p>
                LinkedIn is the largest social network for business contacts.
                Unlike Facebook, for example, it focuses exclusively on building
                business contacts. Companies can use the platform to present
                services and products and to establish business relationships.
                Many people also use LinkedIn for job search or to find suitable
                employees for their own company. In Germany alone, the network
                has over 11 million members. In Austria there are about 1.3
                million.
              </p>
              <br />
              <h3>Why do we use LinkedIn on our website?</h3>
              <br />
              <p>
                We know how busy you are. You can't follow all social media
                channels individually. Even if, as in our case, it would be
                worth it. Because we always post interesting news or reports
                that are worth spreading. That's why we have created the
                possibility on our website to share interesting content directly
                on LinkedIn or to link directly to our LinkedIn page. We
                consider built-in social plug-ins as an extended service on our
                website. The information LinkedIn collects also helps us to show
                possible advertising to people who are interested in our
                services.
              </p>
              <br />
              <h3>What data is stored by LinkedIn?</h3>
              <br />
              <p>
                LinkedIn does not store any personal data simply by integrating
                the social plug-ins. LinkedIn calls this data, which is
                generated by plug-ins, passive impressions. However, if you
                click on a social plug-in, for example to share our content, the
                platform stores personal data as so-called "active impressions".
                This is the case regardless of whether you have a LinkedIn
                account or not. If you are logged in, the collected data is
                assigned to your account. Your browser connects directly to
                LinkedIn's servers when you interact with our plug-ins. This is
                how the company logs various usage data. In addition to your IP
                address, this may include login information, device information,
                or information about your Internet or mobile phone provider. If
                you access LinkedIn services from your smartphone, your location
                (after you allow it) can also be determined. LinkedIn may also
                share this information in "hashed" form with third-party
                advertisers. Hashing means that a data set is transformed into a
                string. This makes it possible to encrypt the data in a way that
                people cannot be identified. Most data about your user behavior
                is stored in cookies. These are small text files that are
                usually set in your browser. But LinkedIn can also use web
                beacons, pixel tags, display tags and other device identifiers.
                Various tests also show which cookies are set when a user
                interacts with a social plug-in. The data found cannot claim to
                be exhaustive and is only used as an example. The following
                cookies were set without being logged in to LinkedIn:
              </p>
              <br />
              <p>Name: bcookie</p>
              <p>Value: =2&34aab2aa-2ae1-4d2a-8baf-c2e2d7235c16311230666-</p>
              <p>
                Purpose: The cookie is a so-called "browser ID cookie" and thus
                stores your identification number (ID).
              </p>
              <p>Expiration date: After 2 years</p>
              <br />

              <p>Name: long</p>
              <p>Value: v=2&long=en-en</p>
              <p>
                Purpose: This cookie stores your preset or preferred language.
              </p>
              <p>Expiration date: after session end</p>

              <br />
              <p>Name: lidc</p>
              <p>Value: 1818367:t=1571904767:s=AQF6KNnJ0G311230666…</p>
              <p>
                Purpose: This cookie is used for routing. Routing records how
                you got to LinkedIn and how you navigate through the site.
              </p>
              <p>Expiration date: after 24 hours</p>
              <br />

              <p>Name: rtc</p>
              <p> Value: kt0lrv3NF3x3t6xvDgGrZGDKkX</p>
              <p>
                Purpose: No further information about this cookie could be
                found.
              </p>
              <p>Expiration date: after 2 minutes </p>
              <br />
              <p>Name: JSESSIONID</p>
              <p>Value: ajax:3112306662900777718326218137</p>
              <p>
                Purpose: This is a session cookie that LinkedIn uses to maintain
                anonymous user sessions through the server.
              </p>
              <p>Expiration date: after session end</p>
              <br />
              <p>Name: bscookie</p>
              <p>Value: "v=1&201910230812...</p>
              <p>
                Purpose: This cookie is a security cookie. LinkedIn describes it
                as a secure browser ID cookie.
              </p>
              <p> Expiration date: after 2 years</p>
              <br />
              <p> Name: fid</p>
              <p>Value: AQHj7Ii23ZBcqAAAA...</p>
              <p>
                Purpose: No further information could be found for this cookie.
              </p>
              <p> Expiration date: after 7 days</p>
              <br />
              <p>
                <br />
                <b>Note:</b> LinkedIn also works with third party providers.
                That's why we recognized the two Google Analytics cookies _ga
                and _gat during our test.
              </p>
              <br />
              <h3>How long and where is the data stored?</h3>
              <br />
              <p>
                As a general rule, LinkedIn keeps your personal information as
                long as the company considers it necessary to provide its
                services. However, LinkedIn will delete your personal
                information when you delete your account. In some exceptional
                cases, LinkedIn may retain some information in aggregate and
                anonymous form even after you delete your account. Once you
                delete your account, other people will not be able to see your
                information within one day. LinkedIn always deletes the data
                within 30 days. LinkedIn does, however, retain data if required
                to do so by law. Data that can no longer be assigned to any
                person remains stored even after the account has been closed.
                The data is stored on different servers in America and probably
                also in Europe.
              </p>
              <br />
              <h3>How can I delete my data or prevent data storage?</h3>
              <br />
              <p>
                You have the right to access and delete your personal data at
                any time. In your LinkedIn account you can manage, change and
                delete your data. You can also request a copy of your personal
                information from LinkedIn. This is how you access the account
                information in your LinkedIn profile: In LinkedIn, click on your
                profile icon and select the "Settings and Privacy" section. Now
                click "Privacy" and then click "Change" in the "How LinkedIn
                uses your data" section. In a short time, you will be able to
                download selected information about your web activity and
                account history. You also have the option of preventing LinkedIn
                from processing data in your browser. As mentioned above,
                LinkedIn stores most of its data through cookies that are set in
                your browser. You can manage, disable or delete these cookies.
                Depending on which browser you have, the administration works
                slightly differently. The instructions for the most common
                browsers can be found here:
                <p>Chrome: Delete, activate and manage cookies in Chrome </p>
                <p>Safari: Manage cookies and website data with Safari </p>
                <p>
                  Firefox: delete cookies to remove data that websites have
                  placed on your computer
                </p>
                <p>Internet Explorer: Delete and manage cookies </p>
                <p>Microsoft Edge: Delete and manage cookies </p>
                You can also set up your browser so that you are always informed
                when a cookie is to be set. Then you can always decide
                individually whether you want to accept the cookie or not.
                LinkedIn is an active participant of the EU-U.S. Privacy Shield
                Framework. This framework ensures a correct data transfer
                between the USA and the European Union. You can learn more about
                it at
                https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0.
                We have tried to provide you with the most important information
                about data processing by LinkedIn. At
                https://www.linkedin.com/legal/privacy-policy you can learn more
                about the data processing of the social media network LinkedIn.
              </p>
              <br />
              <h3>Stripe Privacy Policy</h3>
              <br />
              <p>
                We use on our website a payment tool of the American technology
                company and online payment service Stripe. For customers within
                the EU, Stripe Payments Europe (Europe Ltd., 1 Grand Canal
                Street Lower, Grand Canal Dock, Dublin, Ireland) is responsible.
                This means that if you choose Stripe as your payment method,
                your payment will be processed through Stripe Payments. Data
                necessary for the payment process is transferred to Stripe and
                stored. In this privacy policy we give you an overview of this
                data processing and storage by Stripe and explain why we use
                Stripe on our website.
              </p>
              <br />
              <h3>What is Stripe?</h3>
              <br />
              <p>
                The technology company Stripe offers payment solutions for
                online payments. With Stripe it is possible to accept credit and
                debit card payments in our webshop. Stripe handles the entire
                payment process. A big advantage of Stripe is, for example, that
                you never have to leave our website or store during the payment
                process and that the payment processing is very fast.
              </p>
              <br />
              <h3>Why do we use Stripe for our website?</h3>
              <br />
              <p>
                Of course we want to offer the best possible service with our
                website and our integrated online store, so that you feel
                comfortable on our site and use our offers. We know that your
                time is precious and therefore especially payment transactions
                have to work fast and smoothly. In addition to our other payment
                providers, we have found a partner in Stripe who guarantees a
                secure and fast payment processing.
              </p>
              <br />
              <h3>Which data is stored by Stripe?</h3>
              <br />
              <p>
                If you choose Stripe as your payment method, your personal data
                will also be transmitted to Stripe and stored there. This is
                transaction data. This data includes the payment method (i.e.
                credit card, debit card or account number), bank code, currency,
                amount and date of payment. In addition, your name, e-mail
                address, billing or shipping address, and sometimes your
                transaction history may also be transmitted. This information is
                necessary for authentication. Stripe may also collect your name,
                address, telephone number and country, in addition to technical
                information about your device (such as IP address) for fraud
                prevention, financial reporting and to fully provide its
                services. Stripe does not sell any of your data to independent
                third parties, such as marketing agencies or other companies
                that have nothing to do with the Stripe company. However, the
                data may be passed on to internal departments, a limited number
                of external Stripe partners or to comply with legal
                requirements. Stripe also uses cookies to collect data. Here is
                a selection of cookies that Stripe can set during the payment
                process:
              </p>
              <br />
              <p>Name: m</p>
              <p>
                Value: edd716e9-d28b-46f7-8a55-e05f1779e84e040456311230666-5
              </p>
              <p>
                Purpose: This cookie appears when you select the payment method.
                It stores and recognizes whether you access our website via a
                PC, tablet or smartphone.
              </p>
              <p> Expiration date: after 2 years</p>
              <br />
              <p>Name: __stripe_mid</p>
              <p>
                Value: fc30f52c-b006-4722-af61-a7419a5b8819875de9311230666-1
              </p>
              <p>
                Purpose: This cookie is required to complete a credit card
                transaction. For this purpose the cookie stores your session ID.
              </p>
              <p>Expiration date: after one year</p>
              <br />
              <p>Name: __stripe_sid</p>
              <p>Value: 6fee719a-c67c-4ed2-b583-6a9a50895b122753fe</p>
              <p>
                Purpose: This cookie also stores your ID and is used for the
                payment process on our website by Stripe.
              </p>
              <p>Expiration date: after the end of the session</p>
              <br />
              <h3>How long and where is the data stored?</h3>
              <br />
              <p>
                Personal data is generally stored for the duration of the
                service provision. This means that the data will be stored until
                we dissolve our cooperation with Stripe. However, in order to
                comply with legal and regulatory obligations, Stripe may also
                store personal data for the duration of the service. As Stripe
                is a global company, the data may also be stored in any country
                where Stripe provides services. Data may also be stored outside
                your country, for example in the USA.
              </p>
              <br />
              <h3> How can I delete my data or prevent data storage?</h3>
              <br />
              <p>
                Stripe continues to be a participant in the EU-U.S. Privacy
                Shield Framework, which regulated the correct and secure
                transfer of personal data until July 16, 2020. After the
                European Court of Justice declared the agreement invalid, the
                company no longer relies on this agreement, but still acts in
                accordance with the principles of the Privacy Shield. You always
                have the right of access, correction and deletion of your
                personal data. If you have any questions, you can always contact
                the Stripe team at https://support.stripe.com/contact/email. You
                can delete, deactivate or manage cookies that Stripe uses for
                its functions in your browser. Depending on which browser you
                use, this works in different ways. Please note, however, that
                the payment process may then no longer work. The following
                instructions show you how to manage cookies in your browser:
                <br />
                <p>Chrome: Delete, activate and manage cookies in Chrome </p>
                <br />
                <p>Safari: Manage cookies and website data with Safari </p>
                <br />
                <p>
                  Firefox: delete cookies to remove data that websites have
                  placed on your computer
                </p>
                <br />
                <p>Internet Explorer: Delete and manage cookies </p>
                <br />
                <p>Microsoft Edge: Delete and manage cookies </p>
                <br />
                We have now given you a general overview of the processing and
                storage of data by Stripe. If you would like to obtain more and
                more detailed information, the detailed Stripe privacy policy at
                https://stripe.com/at/privacy is a good source.
              </p>
              <br />
              <h3> BootstrapCDN Privacy Policy</h3>
              <br />
              <p>
                In order to deliver all our individual web pages (sub-pages of
                our website) to you quickly and securely on all devices, we use
                the Content Delivery Network (CDN) BootstrapCDN of the American
                software company StackPath, LLC 2012 McKinney Ave. Suite 1100,
                Dallas, TX 75201, USA. A Content Delivery Network (CDN) is a
                network of regionally distributed servers that are connected to
                each other via the Internet. Through this network, content,
                especially very large files, can be delivered quickly, even
                during high load peaks. BootstrapCDN works by delivering
                so-called JavaScript libraries to your browser. If your browser
                now downloads a file from BootstrapCDN, your IP address is
                transmitted to the company StockPath during the connection to
                the BootstrapCDN server. StackPath also mentions in its own
                privacy policy that the company uses aggregated and anonymized
                data from various services (such as BootstrapCDN) for backup
                extension and for other StackPath services and clients. However,
                all this data cannot identify any person. If you want to prevent
                this data transfer, you can install a JavaScript blocker (see
                for example https://noscript.net/) or disable the execution of
                JavaScript codes in your browser. Please note, however, that
                this means that the website can no longer provide the usual
                service (such as fast loading speed). StackPath is an active
                participant in the EU-U.S. Privacy Shield Framework, which
                regulates the correct and secure transfer of personal data. More
                information can be found at
                https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active.
                For more information about StackPath or BootstrapCDN data
                protection, please visit
                https://www.bootstrapcdn.com/privacy-policy/.
              </p>
              <br />
              <p>
                Source: Created with the privacy generator of AdSimple in
                cooperation with slashtechnik.de
              </p>
            </TextWrapper>
          </Column2>
        </InfoRow>
      </InfoWrapper>
    </InfoContainer>
  );
};

export default PrivacyPolicyEnglish;
